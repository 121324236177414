<template>
  <div class="container">
    <el-card>
      <slot></slot>
    </el-card>
  </div>
</template>

<script>
import { ElCard } from 'element-plus'

export default {
  name: 'AppContainer',
  components: {
    ElCard
  }
}
</script>

<style scoped lang="sass">
.container
  padding: 24px
  flex-grow: 1
</style>
