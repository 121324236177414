<template>
  <app-container class="exclude-data">
    <p style="color: #f44336; font-size: 24px;">
      {{ message }}
    </p>
  </app-container>
</template>

<script>
import AppContainer from '@/components/AppContainer'

export default {
  name: 'PageNotFound',
  components: { AppContainer },
  data() {
    return { message: 'Página não encontrada' }
  }
}
</script>

<style lang="sass" scoped></style>
